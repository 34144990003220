import React from "react"
import { navigate } from "gatsby"
import NotFound from "../assets/pageNotFound/notFound.svg"
import { Button } from "antd"
import "../theme/error.less"

export default function ErrorPage() {
  return (
    <div className="notFoundPageParent">
      <div className="notFoundPageDiv">
        <img src={NotFound} alt="not found illustration" />
        <h1>The page you are looking for is not available</h1>
        <Button type="text" className="goBackBtn" onClick={() => navigate("/")}>
          Go back to Home Page
        </Button>
      </div>
    </div>
  )
}
